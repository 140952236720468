.statistics-calendar {
  .ant-picker-calendar-header {
    @apply justify-center flex-row-reverse flex;
  }
}

.statistics-calendar {
  .ant-picker-panel,
  .ant-picker-calendar-mini,
  .ant-picker-date-panel {
    background: #e7ecee;
    border: 0px !important;
    border-radius: 1rem !important;
  }
}

.statistics-calendar {
  .ant-picker-calendar-mode-switch {
    @apply hidden;
  }
}

.statistics-calendar {
  .ant-picker-calendar-year-select,
  .ant-picker-calendar-month-select {
    @apply mx-1;
  }
}

.statistics-calendar:has(.ant-select-selector) {
  .ant-select-selector {
    @apply bg-triple-blue;
  }
}

.statistics-calendar:has(tr):has(td.ant-picker-cell-in-view) {
  td.ant-picker-cell-in-view {
    @apply text-triple-header;
  }
}

.statistics-calendar:has(table.ant-picker-content):has(thead) {
  th {
    @apply text-triple-header;
  }
}

.statistics-calendar:has(tr):has(.ant-picker-cell) {
  td.ant-picker-cell {
    @apply text-triple-grey;
  }
}
