.error-page {
  @apply bg-triple-background h-screen flex items-center justify-center;

  .page {
    &-icon {
      @apply text-triple-blue text-4xl mb-2;
    }
    &-title {
      @apply text-28 font-light mb-4;
    }

    &-subtitle {
      @apply text-16 w-3/4 mx-auto sm:text-18 text-triple-white font-light;
    }

    &-actions {
      @apply flex flex-col items-center justify-center sm:flex-row mt-6;

      & .divider {
        @apply text-triple-white px-4 my-2;
      }
    }
  }

  .number-code {
    @apply text-[10rem] tracking-[-20px] leading-[150px] sm:text-[15rem] sm:tracking-[-40px] sm:leading-[230px] font-black text-triple-blue uppercase ml-[-2rem] my-4;

    & > span {
      text-shadow: -10px 0px 0px #252331;
    }
  }
}
