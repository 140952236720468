* {
  @apply m-0 p-0 box-border;
}

html {
  display: flex;
  flex-flow: column;
}

body,
#root {
  @apply font-rubik font-normal w-full h-full overflow-hidden bg-triple-background;

  // just for pixel perfect
  // width: 1440px;
  // height: 1024px;
}

a {
  @apply text-triple-blue;

  &:hover,
  &:active {
    @apply text-triple-blue;
  }
}

ul {
  list-style: none;
}

h1 {
  @apply text-26 text-triple-white;
}

*::-webkit-scrollbar {
  height: 6px; /* height of horizontal scrollbar */
  width: 6px; /* width of vertical scrollbar */
}

.no-scrollba::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar-track {
  @apply bg-transparent border border-triple-blue; /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  @apply bg-triple-blue; /* color of the scroll thumb */
}

::selection {
  @apply bg-triple-blue;
}
