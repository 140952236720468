.chart-type {
  @apply flex self-end w-full lg:w-1/2 justify-center lg:justify-start mb-1 lg:mb-0;
}
.chart-type > .chart-type-select {
  @apply inline-block self-end;
  width: 125px;
}

.data-cards {
  @apply flex self-end h-full w-full lg:w-1/2 justify-center lg:justify-start;
}
.water-consumption {
  @apply rounded-xl flex items-center justify-center flex-col bg-triple-blue pt-5;
  width: 150px;
}

.w-consumption {
  width: 100px;
  height: 100px;
  border: 0.5rem solid #f6f6f6;
  border-radius: 50%;
  background: radial-gradient(closest-side, #f6f6f6 79%, transparent 80% 100%),
    conic-gradient(#06a5d3 38%, transparent 0);
  box-shadow: -10px 10px 10px 0px #3d3d3d;
  @apply bg-triple-white flex text-center justify-center items-center flex-col;
}
