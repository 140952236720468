.login-page {
  @apply bg-triple-background flex items-center justify-center h-screen;

  .login {
    &-card {
      @apply bg-triple-background sm:bg-triple-main-blue h-full w-full flex items-center justify-center sm:block sm:h-auto sm:max-w-[33rem] sm:p-8 xl:p-12;
    }

    &-content {
      @apply w-full sm:w-5/6 sm:mx-auto xl:w-full;
    }

    &-title {
      @apply uppercase tracking-widest font-light;
    }

    &-copy {
      @apply mb-6 mt-6 font-light text-16 text-secondary-white;
    }

    &-button {
      @apply w-full sm:w-1/3 mx-auto mt-4 flex items-center justify-center;  
    }
  }
}
