.app {
  @apply flex flex-col w-full h-full overflow-hidden;

  .sidebar-wrapper  {
    // TODO: try figure out a way to do this through config
    &.ant-layout-sider {
      width: 16% !important;
      font-weight: 300 !important;
      min-width: 260px !important;
      flex: 0 0 260px !important;
      max-width: 260px !important;
    }

    .ant-layout-sider-children {
      .ant-menu {
        height: 100%;
  
        &-item:nth-child(6) {
          margin-top: auto;
        }
      }
  
      .sidebar {
        @apply h-full flex flex-col justify-between;
  
        &--mobile {
          @apply z-50 pb-16;
        }
      }
    }
  }  

  .mobile-nav {
    @apply absolute flex left-0 h-full w-full overflow-hidden;
  }

  .main-content {
    @apply bg-triple-background w-full flex items-center justify-center overflow-y-auto;

    .content-wrapper {
      @apply w-full h-full self-start py-4 px-2 md:p-8 overflow-x-hidden relative;
    }
  }
}
