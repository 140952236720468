.statistics-filters
  .ant-select-selector:has(span.ant-select-selection-item:not([title=""])) {
  @apply bg-triple-blue;
}

.statistics-filters .ant-space {
  @apply justify-center lg:justify-start w-full;
}

.statistics-filters .ant-space-item {
  @apply w-full flex justify-center md:justify-start;
}

@media (min-width: 768px) {
  .statistics-filters .ant-space-item {
    width: 125px;
  }
}

.statistics-filters .ant-select {
  width: 125px;
}
