.events {
  @apply flex flex-col h-full;

  &-header {
    @apply flex items-center sm:justify-end lg:justify-between mb-4 md:mb-7;

    &-button,
    &-button.ant-btn,
    &-button.ant-primary {
      @apply w-full flex items-center px-4 sm:px-10;

      &--combined {       
        &-main {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
          @apply w-9/12 px-8;
        }

        &-secondary {
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
          @apply w-3/12;
        }
      }
    }
  }

  ul.ant-pagination.ant-table-pagination {
    @apply bg-[#303343] w-fit rounded p-2 mx-auto;
  }

  .ant-pagination-item-active {
    @apply bg-triple-secondary-blue border-triple-secondary-blue rounded-full font-normal;

    &:hover {
      @apply border-none;
    }
  }
}
