.system-permissions {
  .ant-card {
    border-radius: 0;
  }

  .active-button {
    color: #06a5d3 !important;
  }

  .system-name-title {
    @apply text-10 font-normal m-0 uppercase tracking-widest md:text-18 md:font-normal md:m-0 md:uppercase md:tracking-widest;
  }

  .system-permission-title {
    @apply text-10 font-normal m-0 uppercase tracking-widest md:text-18 md:font-normal md:m-0 md:uppercase md:tracking-widest;
  }

  .property-col-title {
    @apply text-10 font-normal m-0 uppercase tracking-widest md:text-18 md:font-normal md:m-0 md:uppercase md:tracking-widest;
  }

  .floor-col-title {
    @apply text-10 font-normal m-0 uppercase tracking-widest md:text-18 md:font-normal md:m-0 md:uppercase md:tracking-widest;
  }
}
