.ant-layout-sider-children {
  height: 100%;
}

.ant-btn {
  @apply bg-transparent sm:border-2;

  &.ant-btn-primary {
    @apply border-triple-blue uppercase tracking-widest font-light;

    svg {
      @apply fill-triple-blue;
    }

    &:not(:disabled):hover {
      @apply bg-triple-blue;

      svg {
        @apply fill-white;
      }
    }

    &:disabled {
      border-color: #343646;
      @apply text-secondary-grey;

      svg {
        @apply fill-secondary-grey;
      }
    }
  }

  &.ant-btn-default {
    border-color: #51566e;
    @apply bg-transparent uppercase tracking-widest font-light;

    &:not(:disabled):hover {
      @apply text-triple-white border-triple-blue;

      svg {
        @apply fill-triple-blue;
      }
    }

    &:disabled {
      border-color: #343646;
      @apply text-secondary-grey;

      svg {
        @apply fill-secondary-grey;
      }
    }
  }

  &.ant-btn-text {
    &:not(:disabled):hover {
      border-color: #51566e;
      @apply bg-transparent;
    }

    &:disabled {
      @apply text-secondary-grey;
    }
  }

  &.ant-btn-link {
    @apply text-triple-white;

    svg {
      @apply fill-triple-blue;
    }

    &:not(:disabled):hover {
      @apply text-triple-blue;
    }
  }
}

.ant-drawer-close {
  color: rgba(255, 255, 255, 0.45);
  @apply transition-colors p-1;

  &:hover,
  &:active {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.06);
  }

  svg {
    @apply text-lg;
  }
}

.ant-picker-panel {
  border: 1px solid #676383 !important;
  border-radius: 4px !important;
}

.ant-divider.ant-divider-horizontal,
.ant-divider.ant-divider-vertical  {
  border-color: #7f818b;
}

.ant-alert-message {
  @apply uppercase tracking-widest;
}

.ant-alert-error {
  @apply border-triple-red bg-triple-red bg-opacity-10;

  .ant-alert-icon {
    @apply text-triple-red;
  }
}

.ant-alert-success {
  @apply border-triple-green bg-triple-green bg-opacity-10;

  .ant-alert-icon {
    @apply text-triple-green;
  }
}

.ant-alert-warning {
  @apply border-triple-orange bg-triple-orange bg-opacity-10;

  .ant-alert-icon {
    @apply text-triple-orange;
  }
}

.ant-alert-info {
  @apply border-triple-blue bg-triple-blue bg-opacity-10;

  .ant-alert-icon {
    @apply text-triple-blue;
  }
}

.ant-table-cell {
  @apply leading-5;
}

ul.ant-pagination,
ul.ant-pagination.ant-table-pagination {
  margin-left: auto !important;
  @apply bg-[#303343] w-fit rounded p-2;
}

.ant-pagination[disabled] {
  cursor: not-allowed !important;
  color: #999;
}

.ant-pagination[disabled] span:hover {
  cursor: not-allowed !important;
  color: #999;
}

.ant-pagination-item-active {
  @apply bg-triple-secondary-blue border-triple-secondary-blue rounded-full font-normal;

  &:hover {
    @apply border-none;
  }
}