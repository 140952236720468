#update-property-preferences {
  label.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
    background: #393d50;
  }
}
@media (max-width: 1024px) {
  .divider-To {
    display: none;
  }

  .property-details {
    display: flex;
    flex-direction: column;
  }
}

#update-property-preferences .working-days-timepicker {
  width: 150px;
}

#update-property-preferences .working-days-picker {
  width: 150px;
}

@media (max-width: 1440px) {
  #update-property-preferences .working-days-timepicker {
    width: 115px;
  }

  #update-property-preferences .working-days-picker {
    width: 115px;
  }
}

@media (max-width: 425px) {
  .divider-To {
    display: inline-flex;
  }
  #update-property-preferences .working-days-timepicker {
    width: 176px;
  }

  #update-property-preferences .working-days-picker {
    width: 176px;
  }
}
