.sidebar {
  @apply z-50 h-full;

  li {
    &:last-child {
      @apply mt-auto;
    }
    .nav-link {
      @apply flex items-center justify-start rounded-none;

      &.active {
        @apply text-triple-white font-medium;
      }

      & > svg {
        @apply mr-2;
      }
    }
  }

  li[data-menu-id*="forbidden"] {
    cursor: default;
  }
  .ant-menu-item {
    @apply my-3;
  }

  // properly colorize sidebar icons
  .nav-link.active,
  .ant-menu-item:hover {
    svg {
      @apply transition-colors duration-300 fill-triple-blue;

      path,
      g {
        @apply stroke-triple-sidebar transition-colors duration-300;
      }

      .blue-stroke-color {
        @apply stroke-triple-blue;
      }

      .blue-fill-color {
        @apply fill-triple-blue;
      }

      .blue-both-colors {
        @apply stroke-triple-blue fill-triple-blue;
      }

      .purple-stroke-color {
        @apply stroke-triple-sidebar;
      }

      .purple-fill-color {
        @apply fill-triple-sidebar;
      }

      .purple-both-colors {
        @apply stroke-triple-sidebar fill-triple-sidebar;
      }
    }
  }
}
